@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "Wix Madefor Display", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.who-we-are img {
	max-width: 70% !important;
}

.article-img {
	overflow: hidden;
	height: 100%;
	max-height: 223px;
}

@media only screen and (max-width: 1024px) {
	.article-img {
		overflow: hidden;
		height: 100%;
		max-height: 145px;
	}
}

@media only screen and (max-width: 768px) {
	.article-img {
		overflow: hidden;
		height: 100%;
		max-height: 345px;
	}
}

@media only screen and (max-width: 425px) {
	.article-img {
		overflow: hidden;
		height: 100%;
		max-height: 190px;
	}
}

.industria-img {
	overflow: hidden;
	height: 100%;
	max-height: 220px;
}

ul {
	list-style: none; /* Remove default bullets */
}

ul li::before {
	content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
	color: #3991d2; /* Change the color */
	font-weight: bold; /* If you want it to be bold */
	display: inline-block; /* Needed to add space between the bullet and the text */
	width: 1em; /* Also needed for space (tweak if needed) */
}
