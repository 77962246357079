.formulario__input-error {
	font-size: 12px;
	margin-bottom: 0;
	display: none;
}

.formulario__input-error-activo {
	display: block;
	color:lightcoral;
}

.formulario__label {
	display: block;
	font-weight: 700;
	padding: 10 0px;
	cursor: pointer;
	font-size: 14px;
}