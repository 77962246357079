.header-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 5px 30px 5px 20px;
	align-items: center;
	background: black;
}

.header-logo {
	height: 60px;
	max-width: 330px;
}

.header-list {
	display: flex;
	list-style: none !important;
	gap: 30px;
	margin-bottom: 0;
}

.header-list > li {
	text-decoration: none;
	font-size: 16px;
	text-transform: uppercase;
	color: #cbcbcd;
	font-family: 'Poppins';
}

.header-list > li > a:hover {
	text-decoration: underline;
	color: #cbcbcd;
}

.header-list > li::before {
	content: "";
}

.dropdown-list {
	display: flex;
	list-style: none !important;
	flex-direction: column;
	position: absolute;
	z-index: 10;
	margin-top: 20px;
	background-color: black;
	width: 160px;
}

.dropdown-list > li::before {
	content: "";
}

.dropdown-list > li {
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;
	color: #cbcbcd;
	padding: 10px 20px 10px 5px;
	font-family: 'Poppins';
}

.dropdown-list > li > a:hover {
	text-decoration: underline;
	color: #cbcbcd;
}

@media only screen and (max-width: 1024px) {
	.header-list {
		gap: 20px;
	}
	
  }


  .menu-mobile{
	color: #cbcbcd;
  }

  .menu-mobile-nav-close{
	position: fixed;
    z-index: 10;
    background-color: black;
    width: 0;
    height: 100vh;
	transition: 0.5s;
  }

  .menu-mobile-nav-open{
	position: relative;
    z-index: 10;
    background-color: black;
    width: 100%;
    height: 100vh;
    left: 0;
	transition: 0.5s;
  }


  .menu-mobile-list-close {
	display: none;
	list-style: none !important;
	gap: 40px;
	margin-bottom: 0;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	position: relative;
}

.menu-mobile-list-open {
	display: flex;
	list-style: none !important;
	gap: 40px;
	margin-bottom: 0;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	position: fixed;
	width: 100%;
	transition: 0.5s;
	padding-top: 20px;
}


.menu-mobile-list-open > li {
	text-decoration: none;
	font-size: 20px;
	text-transform: uppercase;
	color: #cbcbcd;
	font-family: 'Poppins';
}

.menu-mobile-list-open > li > a:hover {
	text-decoration: underline;
	color: #cbcbcd;
}

.menu-mobile-list-open > li::before {
	content: "";
}

.dropdown-mobile-list {
	display: flex;
	list-style: none !important;
	flex-direction: column;
	margin-top: 20px;
	background-color: black;
	align-items: center;
}

.dropdown-mobile-list > li::before {
	content: "";
}

.dropdown-mobile-list > li {
	text-decoration: none;
	font-size: 16px;
	text-transform: uppercase;
	color: #cbcbcd;
	padding: 10px 0;
	font-family: 'Poppins';
}

.dropdown-mobile-list > li > a:hover {
	text-decoration: underline;
	color: #cbcbcd;
}

.language-list {
	display: flex;
	list-style: none !important;
	flex-direction: column;
	position: absolute;
	z-index: 10;
	margin-top: 23px;
	background-color: black;
	width: auto;
}

.language-list > li::before {
	content: "";
}

.language-list > li {
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;
	color: #cbcbcd;
	padding: 10px 20px 10px 5px;
	font-family: 'Poppins';
	cursor: pointer;
}

.language-list > li:hover {
	text-decoration: underline;
	color: #cbcbcd;
}
